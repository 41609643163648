import type { Style } from '~/@types/cms';

const { colorMapper } = useStyle();

export const useStyleCustom = (style?: Style) => {
    const textColorClass = computed(() => {
        if (!style?.font_color) return '';
        return '!text-[--custom-text-color]';
    });
    const textColorStyle = computed(() => {
        return {
            '--custom-text-color': unref(style?.font_color),
        };
    });

    const buttonColorClass = computed(() => {
        if (!style?.button_color) return '';
        return '!bg-[--custom-button-color]';
    });
    const buttonColorStyle = computed(() => {
        return {
            '--custom-button-color': unref(style?.button_color),
        };
    });

    const buttonLabelColorClass = computed(() => {
        if (!style?.label_color) return '';
        return '!text-[--custom-button-label-color]';
    });
    const buttonLabelColorStyle = computed(() => {
        return {
            '--custom-button-label-color': unref(style?.label_color),
        };
    });

    const backgroundColorClass = computed(() => {
        if (!style?.theme) return '';
        return colorMapper[style.theme];
    });

    const backgroundColorStyle = computed(() => {
        return {
            '--custom-background-color': unref(style?.background_color),
        };
    });

    return {
        textColorClass,
        textColorStyle,
        buttonColorClass,
        buttonColorStyle,
        buttonLabelColorClass,
        buttonLabelColorStyle,
        backgroundColorClass,
        backgroundColorStyle,
    };
};
